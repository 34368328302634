// extracted by mini-css-extract-plugin
export var accordionWrapper = "custom-software-development-module--accordionWrapper--4d10f";
export var advantages = "custom-software-development-module--advantages--ee930";
export var billetShadow = "custom-software-development-module--billet-shadow--8f9de";
export var colorScheme__background__grayPale = "custom-software-development-module--colorScheme__background__gray-pale--cf410";
export var customSoftwareDevBlock = "custom-software-development-module--customSoftwareDevBlock--f5760";
export var flexBlock = "custom-software-development-module--flexBlock--08c6c";
export var headline = "custom-software-development-module--headline--9a0b6";
export var headline_grid = "custom-software-development-module--headline_grid--36ee0";
export var how = "custom-software-development-module--how--5eab4";
export var icomoon = "custom-software-development-module--icomoon--80155";
export var lowercase = "custom-software-development-module--lowercase--fffc1";
export var processDescription = "custom-software-development-module--processDescription--e7c86";
export var processDescription__list = "custom-software-development-module--processDescription__list--cfaa2";
export var strongText = "custom-software-development-module--strongText--81267";
export var strongTextBlock = "custom-software-development-module--strongTextBlock--2bca0";
export var techStack = "custom-software-development-module--techStack--7a325";
export var transition = "custom-software-development-module--transition--c7dfe";
export var transitionBackground = "custom-software-development-module--transition-background--f8142";
export var transitionReverse = "custom-software-development-module--transition-reverse--87178";
export var uppercase = "custom-software-development-module--uppercase--63667";