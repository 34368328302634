import React, { useEffect } from "react"
import ScrollReveal from "scrollreveal"

import { IMG } from "../../const"
import "../../assets/styles/commons/lists/_common__list__techLogos-rounded.scss"
import * as styles from "./tech-stack.module.scss"

const DEV_TECHNOLOGIES = [
  { name: "ReactJS", icon: "react.svg" },
  { name: "Angular", icon: "angular.svg" },
  { name: "Laravel", icon: "laravel.svg" },
  { name: "Django", icon: "django.svg" },
  { name: "React Native", icon: "react-native.svg" },
  { name: "Python Flask", icon: "flask-icon.svg" },
  { name: "Ruby on Rails", icon: "ruby-on-rails.svg" },
]

const DESIGNER_TECHNOLOGIES = [
  { name: "Figma", icon: "figma.svg" },
  { name: "InVision", icon: "invision.svg" },
  { name: "Miro", icon: "miro-icon.svg" },
  { name: "Flowmapp", icon: "flowmapp-icon.svg" },
]

const Technology = ({ technology }) => (
  <li>
    <figure>
      <img
        src={`${IMG.ICONS}/logos/${technology.icon}`}
        width="32"
        height="32"
        loading="lazy"
        alt={technology.name}
      />
      <figcaption>{technology.name}</figcaption>
    </figure>
  </li>
)

const DesignerAndDev = ({ designer, developer }) => {
  useEffect(() => {
    ScrollReveal().reveal(`#designer-technologies li`, {
      duration: 1500,
      useDelay: "onload",
      interval: 50,
      delay: 50,
    })
    ScrollReveal().reveal(`#dev-technologies li`, {
      duration: 1500,
      useDelay: "onload",
      interval: 50,
      delay: 50,
    })
  }, [])

  const designerTechnologies = designer ? designer : DESIGNER_TECHNOLOGIES
  const developerTechnologies = developer ? developer : DEV_TECHNOLOGIES

  return (
    <div className={styles.techStack__wrapper}>
      <div>
        <h5>Designers</h5>
        <ul
          className="common__list__techLogos-rounded"
          id="designer-technologies"
        >
          {designerTechnologies.map(technology => (
            <Technology key={technology.name} technology={technology} />
          ))}
        </ul>
      </div>
      <div>
        <h5>Developers</h5>
        <ul className="common__list__techLogos-rounded" id="dev-technologies">
          {developerTechnologies.map(technology => (
            <Technology key={technology.name} technology={technology} />
          ))}
        </ul>
      </div>
    </div>
  )
}

export default DesignerAndDev
