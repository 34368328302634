import React from "react"
import { IMG } from "../const"

export const OPTIONS_LIST = [
  {
    id: 1,
    title: "Startups",
    text:
      "that require analytics dashboards, but lack a tech team in the early stages.",
  },
  {
    id: 2,
    title: "Founders",
    text:
      "of small and medium-sized businesses that want to gain a market advantage.",
  },
  {
    id: 3,
    title: "CTO",
    text:
      "in need of commercial software modifications for individual requirements.",
  },
  {
    id: 4,
    title: "Consulting",
    text: "that meets client needs at a competitive level.",
  },
]

export const TITLES = {
  process_description: {
    title: (
      <>
        <strong>
          custom <br />
          software
        </strong>
        <br />
        development service process description
      </>
    ),
    strongText: (
      <>
        when you <br />
        contact us…
      </>
    ),
    uppercase: (
      <>
        simple &
        <br /> effective methods
      </>
    ),
    withIndent: true,
  },
  advantages: {
    title: (
      <>
        why build
        <br />
        <strong>a custom software</strong> with the lasoft team?
      </>
    ),
    strongText:
      "When you work with us, you get high-quality software within an agreed budget",
    uppercase: "advantages",
    withIndent: true,
  },
  technologies: {
    title: (
      <>
        our tech <strong>stack</strong>
      </>
    ),
    strongText: "custom software  development tech stack",
    uppercase: (
      <>
        modern
        <br /> technologies
      </>
    ),
    withIndent: false,
  },
  case_studies: {
    title: (
      <>
        case studies we're <strong>proud of:</strong>
      </>
    ),
    strongText:
      "we use a structured and tested approach to provide beautiful digital services for our partners",
    uppercase: (
      <>
        cases from
        <br />
        10+ industries
      </>
    ),
    withIndent: true,
  },
  structured: {
    title: (
      <>
        how does <strong>custom software development</strong> work?
      </>
    ),
    strongText:
      "custom software development is the process of discovering, designing, developing, and supporting software products for a specific set of users, functions, or organizations",
    uppercase: "structured & tested",
    withIndent: true,
  },
  colored: {
    title: "interested in developing  custom software?",
    strongText: "request a consultation today",
    uppercase: "Project estimate",
    linkClassName: "customsoftware-estimate",
    link: "#contact-form",
    imageDesktop: `${IMG.CUSTOM_SOFTWARE_DEVELOPMENT}/scheme-white.svg`,
    imageDesktopWidth: 818,
    imageDesktopHeight: 92,
    withIndent: false,
  },
}

export const PROCESS_DESCRIPTION = [
  {
    id: 1,
    title: "Intro Meeting",
    text: "You will meet with the LaSoft team to discuss the idea.",
  },
  {
    id: 2,
    title: "Discovery",
    text:
      "We will uncover your problem and need, analyze the market, and give you a timeline and budget for the project.",
    link: "/mvp-development/",
  },
  {
    id: 3,
    title: "UX/UI design",
    text:
      "We create a complete product visualization and a clickable prototype.",
    link: "/product-design/",
  },
  {
    id: 4,
    title: "Development",
    text: "Step-by-step product implementation and testing by our experts.",
  },
  {
    id: 5,
    title: "Support",
    text: "We’ll also support your product after release.",
    link: "/project-support/",
  },
]

export const ADVANTAGES = {
  items: [
    {
      id: 1,
      title: "Cost Savings",
      text:
        "We build MVP products with our customizable low-code solution to reduce up to 35% of your time and budget to launch your MVP. In addition, our team can develop new features and customize the solution after the first launch.",
    },
    {
      id: 2,
      title: "Transparent Communication",
      text:
        "We know how vital transparent communication with customers is throughout the service delivery process. That's why our delivery managers constantly communicate with customers about all critical aspects of product development.",
    },
    {
      id: 3,
      title: "Deliver on Time",
      text:
        "Our processes enable us to deliver finished digital solutions and individual interim results on time.",
    },
    {
      id: 4,
      title: "Experience",
      text:
        "With 10 years of experience in delivering custom software products, our professional teams have gained hands-on experience in discovery, design, development, and launch. We have worked on 85 large and small projects across over 10 international markets.",
    },
    {
      id: 5,
      title: "One-stop Shop",
      text:
        "We are a full-cycle software product development service. We cover everything from market research, product, and UX/UI design to coding, testing, and support of software products. We don’t need to work with contractors, as our professional teams have the required expertise.",
    },
  ],
}

export const STAGES = [
  {
    id: 1,
    title: "Discovery Stage",
    text:
      "The discovery phase is the initial phase in which the Product Manager discusses the product with customers, listens to their suggestions, and analyzes competitors' products. This type of process represents the line of inquiry by looking at various sources of qualitative and quantitative data. The starting points are usually surveys, documentation of previous projects, competitive research, focus groups, interviews and reports.",
  },
  {
    id: 2,
    title: "Product Design Stage",
    text:
      "The process of identifying the market opportunity and defining a problem, as well as the steps your team takes in conceptualizing, developing, and launching the product.",
  },
  {
    id: 3,
    title: "Product Development Stage",
    text: (
      <>
        The product development process brings a new idea to life, focusing on
        solving customers’ problems. The key benefits are the focus on product
        discovery and addressing the target audience's pain points&nbsp;—
        gathering customer insights through research, surveys, interviews, and
        monitoring interactions.
      </>
    ),
  },
  {
    id: 4,
    title: "Product Support Stage",
    text:
      "Systems of complex products and services refer to labor services, which can be performed by the manufacturer, or the product parties created by the product. These services can be provided by several types of vendors and software publishers. The product support stage helps discover the main problems with product functioning, enlarging functionality, and supporting product functioning.",
  },
]

export const DEV_TECHNOLOGIES = [
  { name: "ReactJS", icon: "react.svg" },
  { name: "Angular", icon: "angular.svg" },
  { name: "Laravel", icon: "laravel.svg" },
  { name: "Django", icon: "django.svg" },
  { name: "React Native", icon: "react-native.svg" },
  { name: "Python Flask", icon: "flask-icon.svg" },
  { name: "Java", icon: "java.svg" },
  { name: ".NET", icon: "dotnet.svg" },
]
